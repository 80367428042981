import { MINUTE_IN_MS } from '@shared/util/date'
import { getPartner } from '@common/router/index'
import type { FilterItem } from '@common/constants/filter'
import { ApiOptions, getRequest, getRequestWithCache, postRequest } from '@common/api/index'

export interface OrganizationSetting {
  organizationId: number
  hideQingshu: boolean
  pxSchoolId: number
  pxYxkSchoolId: number
  hasSpecialHome?: boolean
  showPrice: boolean
  onlinePay: boolean
  distributor: boolean
  hasDistributor: boolean
  yxkDiscount?: number | null
  organizationDomain?: string | null
  hasMall: boolean
  balance?: number
  showYxkPrice: boolean
  organizationType: number
  joinClassPageStudyTypeList: number[]
  isShowStudyMessageWxApp: boolean
  isOpenOfficialAccount: boolean
  isOpenCourseStudentWithSms: boolean
  isContinueClassStudentWithSms: boolean
  allowAsk: boolean
  allowComment: boolean
  useOldCombo: boolean
  imTicketUrl: string
}
export const getOrganizationSetting = () => {
  const { partnerId } = getPartner()
  return getRequest<OrganizationSetting>(
    new ApiOptions({
      url: `/net/course/${partnerId}/Teacher/Organization/GetOrganizationSetting`,
    }),
  )
}

interface ClassFilterRequest {
  courseType: 1 | 2
  sell?: boolean
  showProduct?: boolean
}
interface ClassFilterResponse {
  conditions: FilterItem[]
}
export const getOrganizationClassFilter = (params: ClassFilterRequest) => {
  const { partnerId } = getPartner()
  return getRequestWithCache<ClassFilterResponse>(
    new ApiOptions({
      url: `/net/course/${partnerId}/Teacher/Organization/GetClassFilter`,
      args: params,
    }),
    { expireTime: 0.5 * MINUTE_IN_MS },
  )
}

interface Organization {
  partnerType: string
  partnerId: number
}
export const getOrganizationByDomain = (domain?: string) => {
  return getRequestWithCache<Organization>(
    new ApiOptions({
      url: '/net/course/Svc/Organization/GetOrganizationByDomain',
      args: { domain: domain || location.origin.replace(/^http(?:s)?:\/\//, '') },
    }),
  )
}

export interface MallStyleConfig {
  id: number
  type: number
  name: string
  previewImg: string
}
export interface MallColorConfig {
  id: number
  color: string
}
export interface MallStyleSetting {
  styles: MallStyleConfig[]
  colors: MallColorConfig[]
}
export const getOrganizationStyleSetting = () => {
  const { partnerId } = getPartner()
  return getRequest<MallStyleSetting>(
    new ApiOptions({
      url: `/net/course/${partnerId}/Teacher/Organization/GetOrganizationStyleSetting`,
    }),
  )
}

interface MallStyleSettingUpsertRequest {
  styleId: number
  colorId: number
}
export const upsertOrganizationStyleSetting = (params: MallStyleSettingUpsertRequest) => {
  const { partnerId } = getPartner()
  return postRequest<MallStyleSetting>(
    new ApiOptions({
      url: `/net/course/${partnerId}/Teacher/Organization/UpsertOrganizationStyleSetting`,
      args: params,
    }),
  )
}

interface IOrganizationUpdateRequest {
  settingType: number
  settingValue: string
}

export const updateOrganizationSetting = (params: IOrganizationUpdateRequest) => {
  const { partnerId } = getPartner()
  return postRequest(
    new ApiOptions({
      url: `/net/course/${partnerId}/Teacher/Organization/OrganizationSetting/Update`,
      args: params,
    }),
  )
}

const ORGANIZATION_SETTING_STATUS = Object.freeze({
  Close: '0',
  Open: '1',
})
export const updateSetting = (settingType: number, settingValue: boolean | string) => {
  if (typeof settingValue === 'string') {
    return updateOrganizationSetting({ settingType, settingValue })
  }
  const valueString = settingValue ? ORGANIZATION_SETTING_STATUS.Open : ORGANIZATION_SETTING_STATUS.Close
  return updateOrganizationSetting({ settingType, settingValue: valueString })
}

export interface SmsContent {
  key: string
  value: string
}

export const getSmsContentSetting = () => {
  const { partnerId } = getPartner()
  return getRequest<SmsContent[]>(
    new ApiOptions({
      url: `/net/course/${partnerId}/Teacher/Organization/OrganizationSetting/SmsContent`,
    }),
  )
}

export const getLiveSetting = () => {
  const { partnerId } = getPartner()
  return getRequest(
    new ApiOptions({
      url: `/net/course/${partnerId}/Teacher/Organization/LiveSetting`,
    }),
  )
}

export const liveSettingUpdate = (settingValue: String) => {
  const { partnerId } = getPartner()
  return postRequest(
    new ApiOptions({
      url: `/net/course/${partnerId}/Teacher/Organization/LiveSetting/Update`,
      args: { settingValue },
    }),
  )
}
